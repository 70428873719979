import logo from './logo.svg';
import alexa from './alexa.png';
import google from './Google.png';
import './App.css';
import {RouterProvider, createBrowserRouter, useNavigate} from 'react-router-dom';
import axios from 'axios';
import React, {useState} from "react";
// Ensures cookie is sent
axios.defaults.withCredentials = true;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const serverUrl = "https://cloudio.bhonofre.pt/authorization";

const RequestCode = () => {
    const [email, setEmail] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const requestCode = async () => {
        try {
            await axios.get(`${serverUrl}/oauth/request-code`, {
                params: {      // eslint-disable-next-line camelcase
                    email: email
                }
            }).then(data => {
                    navigate('/link');
                }
            );

        } catch (err) {
            setError("Invalid User! Please check your Id on OnOfre App.");
        }
    }
    return <>
        <label className="link" htmlFor="email">LINK</label>
        <img className="client" alt={params.client} src={params.client === "Google" ? google : alexa}/>
        <label className="info" htmlFor="email">Enter Your Onofre ID or E-mail Associated with Your Account.</label>
        <input placeholder="Your ID or E-Mail" onChange={event => setEmail(event.target.value)} type="email" id="email"
               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" size="30" required/>
        <span className="error">{error}</span>
        <button className="btn" onClick={requestCode}>Request Code</button>
        <p className="advise">By Signing In, You´re Allowing {params.client}  to Control Your Devices.</p>
    </>
}
const LinkAccount = () => {
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);
    const handleLogin = async () => {
        try {
            const res = await axios.post(`${serverUrl}/oauth/authenticate`, {
                code: code
            }, {
                params: {      // eslint-disable-next-line camelcase
                    redirect_uri: params["redirect_uri"],
                    client_id: params["client_id"],
                    state: params["state"]
                }
            });
            // Navigate to consent screen
            const location = res.headers.location;
            if (location) {
                window.location.replace(location);
            }
        } catch (err) {
            setError("Invalid Code!");
        }
    }
    return <>
        <label className="link" htmlFor="email">LINK</label>
        <img className="client" alt={params.client} src={params.client === "Google" ? google : alexa}/>
        <label className="info" htmlFor="code">Enter The Code Provided</label>
        <input placeholder="Your Code" onChange={event => setCode(event.target.value)} type="text" id="code" size="10" required/>
        <span className="error">{error}</span>
        <button className="btn" onClick={handleLogin}>Link with OnOfre</button>
    </>
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <RequestCode/>,
    },
    {
        path: '/link',
        element: <LinkAccount/>,
    }
]);

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <RouterProvider router={router}/>
            </header>
        </div>
    );
}

export default App;
